<template>
  <div class="head">
    <div class="topTitle paddingL20 paddingT10">
      <el-col :span="12">
        <titleModule title="制作节目"></titleModule>
      </el-col>
      <el-col :span="12">
        <div class="handleBtn">
          <el-button size="medium" @click="$router.go(-1)">返回</el-button>
          <el-button size="medium" type="success" @click="save" v-if="!this.$route.query.isDetail"
            >{{ $t('button.preservation') }}</el-button
          >
          <el-button size="medium" @click="getPreview">预览</el-button>
        </div>
      </el-col>
    </div>
    <div class="Wrapper">
      <el-form
        label-position="right"
        label-width="140px"
        :model="formInline"
        :rules="rules"
        style="width: 65%"
        ref="ruleForm"
      >
        <el-form-item label="节目名称:" prop="programName">
          <el-input
            v-model="formInline.programName"
            :disabled="isEdit"
            style="width: 400px"
            placeholder="请输入素材名称"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="请选择素材:">
          （系统根据素材添加顺序播放）
        </el-form-item>
        <!--列表区域-->
        <div class="tableWrapper">
          <el-table  :data="tableData" style="width: 800px">
            <el-table-column prop="materialName" :label="$t('searchModule.Material_Name')" align="center"></el-table-column>
            <el-table-column prop="playTime" label="播放时长" align="center"></el-table-column>
            <el-table-column
              align="center"
              label="操作"
              width="400"
              type="index"
              v-if="!this.$route.query.isDetail"
            >
              <template slot-scope="scope">
                <el-button
                  class="el-icon-bottom"
                  v-if="(scope.$index != tableData.length-1)"
                  @click="downGo(tableData, scope.$index)"
                ></el-button>
                <el-button
                  class="el-icon-top"
                  v-if="scope.$index != 0"
                  @click="upGo(tableData, scope.$index)"
                ></el-button>
                <el-button type="text" size="small" @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-form-item v-if="!this.$route.query.isDetail">
          <el-button type="primary" @click="addDomain">添加素材</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      class="dialogVisible"
      title="请选择节目单素材"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <el-table :data="tableMatter" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center"> </el-table-column>
        <el-table-column prop="materialName" :label="$t('searchModule.Material_Name')" width="600"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!--<div  v-if="previewVisible">-->
    <!--<img :src="mediaResourceUrlList"-->
    <!--id="myVideo"-->
    <!--:width="400"-->
    <!--:height="500" >-->
    <!--</div>-->
    <div class="previewBox" @click.stop="previewVisible = false" v-if="previewVisible">
      <div>
        <div
          class="preview"
          :style="{ width: showImg.screenWidth + 'px', height: showImg.screenHeight + 'px' }"
        >
          <div
            class="imgPreview"
            :style="{ left: showText.mediaX + 'px', top: showText.mediaY + 'px' }"
            v-if="materialType == 1 || materialType == 2 || materialType == 10"
          >
            <img
              :src="showImg.mediaResourceUrl"
              :width="showImg.mediaShowWidth"
              :height="showImg.mediaShowHeight"
              alt=""
            />
          </div>
          <div
            class="imgPreview"
            :style="{ left: showText.mediaX + 'px', top: showText.mediaY + 'px' }"
            v-if="materialType == 4 || materialType == 12"
          >
            <video
              :src="showImg.mediaResourceUrl"
              :width="showImg.mediaShowWidth"
              :height="showImg.mediaShowHeight"
              controls
              autoplay
            ></video>
          </div>
          <div
            class="text"
            :style="{
              left: showText.textX + 'px',
              top: showText.textY + 'px',
              width: showText.textShowWidth + 'px',
              height: showText.textShowHeight + 'px',
              fontFamily: textFont,
              fontSize: showText.textFontSize + 'px',
              color: styleFontColor,
              textAlign: styleTextAlign,
              fontWeight: showText.textFontStyle === 1 ? 600 : 400,
              fontStyle: showText.textFontStyle === 2 ? 'italic' : 'normal',
              textDecoration: showText.textFontStyle === 4 ? 'underline' : 'normal'
            }"
          >
            {{ showText.textContent }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleModule from "@/components/titleModule/titleModule";
export default {
  data() {
    return {
      formInline: {
        programName: ""
      },
      dialogVisible: false,
      previewVisible: false,
      tableMatter: [],
      previewList: [],
      showImg: {},
      showText: {},
      selectText: [],
      pageSize: 15,
      page: 1,
      tableData: [],
      ProgramList: [],
      isEdit: false,
      materialIdsList: [],
      mediaResourceUrlList: "",
      counter: 0,
      showTime: "",
      materialType: "",
      rules: {
        programName: [{ required: true, message: "请输入素材名称", trigger: "blur" }]
      },
      timer: null
    };
  },
  methods: {
    addDomain() {
      this.dialogVisible = true;
      this.getMaterialList();
    },
    submit() {
      this.tableData.push(...this.selectText);
      this.dialogVisible = false;
    },
    //         cycle(){
    //            document.getElementById("banner").src = banners[counter];
    //             setInterval(cycle,1000)
    //        },
    getshowImg() {
      this.counter = 0;
      this.$axios
        .post("/acb/2.0/ydpProgram/preview", {
          data: {
            materailIds: this.materialIdsList.join(",")
          }
        })
        .then(res => {
          if (res.state == 0) {
            if (res.value) {
              this.previewList = res.value;
              this.showImg = res.value[this.counter];
              this.showTime = res.value[this.counter].playTime;
              this.materialType = res.value[this.counter].materialType;
              this.showText = res.value[this.counter];
              clearInterval(this.timer);
              this.timer = setInterval(() => {
                this.counter++;
                if (this.counter > this.previewList.length - 1) {
                  this.counter = 0;
                }
              }, this.showTime * 1000);
              this.previewVisible = true;
            }
          } else {
            this.previewList = [];
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    // 预览图片
    getPreview() {
      if (this.tableData.length == 0) {
        this.$alert("请选择素材后预览", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return;
      }
      this.materialIdsList = this.tableData.map(item => item.materialId);
      this.getshowImg();
      //            setInterval(this.showImg, 3000)
    },
    handleDelete(index, row, c) {
      this.tableData.splice(index, 1);
    },
    handleSelectionChange(val) {
      this.selectText = val;
    },
    upGo(fieldData, index) {
      if (index != 0) {
        this.tableData[index] = this.tableData.splice(index - 1, 1, this.tableData[index])[0];
      } else {
        this.tableData.push(fieldData.shift());
      }
    },
    downGo(fieldData, index) {
      if (index != fieldData.length - 1) {
        fieldData[index] = fieldData.splice(index + 1, 1, fieldData[index])[0];
      } else {
        fieldData.unshift(fieldData.splice(index, 1)[0]);
      }
    },
    getMaterialList() {
      this.$axios.get("/acb/2.0/ydpMaterial/open/list", {}).then(res => {
        if (res.state == 0) {
          this.tableMatter = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    getProgramDetail() {
      this.$axios
        .get("/acb/2.0/ydpProgram/query", {
          data: {
            programId: this.$route.query.programId
          }
        })
        .then(res => {
          if (res.state == 0) {
            this.formInline = res.value;
            this.tableData = res.value.ydpMaterialSummaryList;
            for (var i = 0; i < this.tableData.length; i++) {
              this.materialIdsList.push(this.tableData[i].materialId);
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        });
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.tableData.length == 0) {
            this.$alert("请至少添加一个素材", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return;
          }
          if (this.tableData.length > 120) {
            this.$alert("每个节目最多可选择120个素材", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
            return;
          }
          let index = 1;
          for (var i = 0; i < this.tableData.length; i++) {
            this.ProgramList.push({
              ydpMaterialId: this.tableData[i].materialId,
              materiaLocation: index++
            });
          }
          let url1 = "/acb/2.0/ydpProgram/add?programName=" + this.formInline.programName;
          let url2 =
            "/acb/2.0/ydpProgram/update?programName=" +
            this.formInline.programName +
            "&programId=" +
            this.$route.query.programId;
          let opt = {
            method: "post",
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8"
              }
            },
            url: this.isEdit ? url2 : url1,
            data: this.ProgramList,
            success: res => {
              if (res.state == 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              }
            }
          };
          this.$request(opt);
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    labelText() {
      const { materialType } = this.showText;
      if (materialType === 1 || materialType === 2 || materialType === 10) {
        return "图片";
      } else if (materialType === 4 || materialType === 12) {
        return "视频";
      }
      return "图片";
    },
    textFont() {
      const { textFont } = this.showText;
      let obj = {
        "1": "SimHei",
        "2": "KaiTi",
        "3": "SimSun",
        "4": "STFangsong",
        "5": "LiSu"
      };
      return obj[textFont] || "Times New Roman";
    },
    styleFontColor() {
      const { textFontColor } = this.showText;
      let obj = {
        "1": "#ff0000",
        "2": "#008000",
        "3": "#0000ff",
        "4": "#ffff00",
        "5": "#ee82ee",
        "6": "#00ffff",
        "7": "#ffffff",
        "8": "#000000"
      };
      return obj[textFontColor];
    },
    styleTextAlign() {
      const { textAlignment } = this.showText;
      let obj = {
        "0": "left",
        "1": "right",
        "2": "center"
      };
      return obj[textAlignment];
    }
  },
  watch: {
    counter(val) {
      this.showImg = this.previewList[val];
      this.showTime = this.previewList[val].playTime;
      this.showText = this.previewList[val];
      this.materialType = this.previewList[val].materialType;
    }
  },
  components: {
    titleModule
  },
  mounted() {
    if (this.$route.query.programId) {
      this.isEdit = true;
      this.getProgramDetail();
    }
  }
};
</script>
<style lang="stylus" scoped>
.head
    height 100%
.topTitle
    display flex
    justify-content space-between
    align-items center
    background-color #ffffff
.Wrapper
    background-color #ffffff
    padding 20px 0
.previewBox
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index:999
    .preview
        margin: 0 auto;
        z-index: 999;
        background: #000;
        position: relative;
        margin-top: 20%;
        transform: translateY(-50%);
        .imgPreview
            position absolute
        .text
            position absolute
            width 100%
            overflow hidden
</style>
